import { useReducer } from "react";
import sliderReducer from "../reducer/sliderReducer";

function useSlider(itemsLength) {

    const initialState = {
        count: 0,
        length: itemsLength
    }

    const [state, dispatch] = useReducer(sliderReducer, initialState)

    return { state, dispatch }
}

export { useSlider };