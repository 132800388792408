import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CustomLink = ({ link, external, children, openNewTab, className, ...otherProps }) => {

    return (
        <>
            {!external ?
                <AnchorLink className={className} gatsbyLinkProps={otherProps} to={link}>{children}</AnchorLink> :
                <a className={className} href={link} {...(openNewTab ? { target: "_blank", rel: "noreferrer" } : {})} {...otherProps} >{children}</a>
            }
        </>
    )
}

export default CustomLink
