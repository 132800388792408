
export function ArrToNestedArr(arr, key) {
    const obj = arr.reduce((prev, curr) => {
        if (!prev[curr[key]]) {
            return { ...prev, [curr[key]]: [curr] }
        } else {
            return { ...prev, [curr[key]]: [...prev[curr[key]], curr] }
        }
    }, [])

    return Object.keys(obj).reduce((prev, key) => { return [...prev, obj[key]] }, [])
}