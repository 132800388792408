import React, { useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import useKeyEsc from "../../hooks/useKeyEsc";
import "./modal.styles.scss";

const Modal = ({ children, isOpen, handleIsOpenModal = () => null, handleIsOpen, setIsOpen }) => {

    const modalRef = useRef();

    useKeyEsc(setIsOpen);

    return (
        <div ref={modalRef} className={`modal${isOpen ? " open" : ""}`} onClick={(e) => handleIsOpenModal(e, modalRef)}>
            <div className="modal-wrapper">
                <button className="close__btn">
                    <AiOutlineClose onClick={handleIsOpen} />
                </button>
                {children}
            </div>
        </div>

    )
}

export default Modal;
