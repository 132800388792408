import React, { useRef, useLayoutEffect } from "react";
import Carousel from "react-multi-carousel";
import "./multiCarousel.styles.scss";
import "react-multi-carousel/lib/styles.css";
import SliderArrow from "../sliderArrow/SliderArrow";


const MultiCarousel = ({ children, height = "", itemPerSlide, slide, ...props }) => {


    // const carouselRef = useRef()


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    // useLayoutEffect(() => {
    //     if (slide !== undefined) {
    //         carouselRef.current.goToSlide(slide);
    //     }
    // }, [slide]);


    return (
        <Carousel
            // ref={carouselRef}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            keyBoardControl={true}
            containerClass={`carousel__container ${height}`.trim()}
            sliderClass="carousel__slider"
            itemClass="carousel__item"
            customRightArrow={<SliderArrow direction="right" />}
            customLeftArrow={<SliderArrow direction="left" />}
            {...props}
        >
            {children ? children : <></>}
        </Carousel>
    );
};

export default MultiCarousel;
