import React from "react";
import "./imageModalWrapper.styles.scss";

const ImageModalWrapper = ({ slides, handleIsOpen, children }) => {

    //TODO Check how you can improve modularity structure and remove nested renderProps

    return (
        <div className="img-modal-wrapper__content">
            {children}
        </div>
    )
}

export default ImageModalWrapper;
