import React from "react";
import TextCard from "../textCard/TextCard";
import Button from "../button/Button";

const Hero = ({ buttons, ...otherProps }) => {

    return (
        <>
            <TextCard {...otherProps} />
            <div className="box-pad-s center">
                {buttons.map((button, index) => <Button link={button.link} externalLink openNewTab key={index}>{button.label}</Button>)}
            </div>
        </>
    )
}

export default Hero
