import GatsbyLink from "gatsby-link";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import ConditionalComponentWrapper from "../conditionalComponentWrapper/ConditionalComponentWrapper";
import CustomLink from "../customLink/CustomLink";
import "./button.styles.scss";


const Button = ({
  negative,
  color = "",
  outline,
  disabled,
  isLoading,
  onClick,
  transparent,
  link,
  children,
  arialLabel,
  externalLink,
  openNewTab,
  style
}) => {


  return (
    <ConditionalComponentWrapper
      condition={link}
      wrapper={children => <CustomLink style={{ width: "fit-content" }} external={externalLink} openNewTab={openNewTab} link={link}>{children}</CustomLink>}
    >
      <button
        style={style}
        aria-label={arialLabel}
        disabled={disabled}
        className={`button${negative ? " negative" : ""}${outline ? " outline" : ""}${disabled ? " disabled" : ""}${transparent ? " transparent" : ""}${color}`.trim()}
        onClick={onClick}>
        {!isLoading ? children : <ClipLoader className="spinner" size={30} />}
      </button>
    </ConditionalComponentWrapper>
  );
};

export default Button;
