import React, { useState } from "react";
import "./imageGallery.styles.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ImageModalWrapper from "../imageModalWrapper/ImageModalWrapper";
import Modal from "../modal/Modal";
import { onSelect, setLength } from "../../reducer/actionTypes";
import Slider from "../slider/Slider";
import DetailImage from "../detailImage/DetailImage";
import { useSlider } from "../../hooks/useSlider";
import { useOpen } from "../../hooks/useOpen";
import MultiCarousel from "../multiCarousel/MultiCarousel";

const ImageGallery = ({ images, children, numberShowElements = 100, animation = "" }) => {

  const { state, dispatch } = useSlider(images.length);
  const { isOpen, setIsOpen, handleIsOpen, handleIsOpenModal } = useOpen();

  // const [slide, setSlide] = useState()

  return (
    <div className="image-gallery">
      <Modal isOpen={isOpen} handleIsOpenModal={handleIsOpenModal} handleIsOpen={handleIsOpen} setIsOpen={setIsOpen} >
        <ImageModalWrapper >
          <Slider slides={images} state={state} dispatch={dispatch}>
            {/* <MultiCarousel slide={slide} itemPerSlide={1} height="max"  keyBoardControl={true} > */}
            {/* {images.map((image, index) => <DetailImage key={index} image={image.image} alt={image.alt} />)} */}
            <DetailImage />
            {/* </MultiCarousel> */}
          </Slider>
        </ImageModalWrapper>
      </Modal>
      <MultiCarousel infinite={true} >
        {images.map((item, index) => {
          return (index < numberShowElements &&
            <div className={`image-gallery__item`.trim()} key={index} onClick={() => {
              dispatch({ type: onSelect, payload: index });
              // setSlide(index)
              handleIsOpen();
            }}>
              <GatsbyImage className={`image ${animation}`.trim()} objectFit="cover" image={getImage(item.image)} alt={item.alt} />
              {children &&
                <div className="image-gallery__item__overlay">
                  {children(item.topic)}
                </div>}
            </div>)
        }
        )}
      </MultiCarousel>
    </div>
  )
}

export default ImageGallery;
