import React from "react";
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import "./imageBackground.styles.scss";

const ImageBackground = ({ children, img, imgAlt, opacity = "", animation = "", ...otherProps }) => {
    const image = getImage(img);
    return (
        <BgImage
            image={image}
            className={`image-background ${animation}`.trim()}
            alt={imgAlt}
            {...otherProps}
        >
            <div className={`image-background__filter ${opacity}`.trim()}></div>
            {children}
        </BgImage>
    )
}

export default ImageBackground;

