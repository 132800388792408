import { icons } from "../constants/contactLinks";
import { ArrToNestedArr } from "./utils";

export function PrismicSliceManipulation(arr) {
    return (
        arr.map((item) => {
            switch (item.slice_type) {
                case "call_to_action":
                    return {
                        content: item.primary.content.html,
                        label: item.primary.label,
                        slug: item.primary.slug,
                        title: item.primary.action_title,
                        slice_type: item.slice_type,
                        image: item.primary.image,
                        buttons: item.items.map(({ button_link, button_label, button_color }) => { return { link: button_link.url, label: button_label, color: button_color } })
                    }
                case "text_card":
                    return {
                        content: item.primary.content.html,
                        title: item.primary.text_card_title,
                        slice_type: item.slice_type,
                    }
                case "image_gallery_item":
                    return {
                        slice_type: item.slice_type,
                        title: item.primary.title,
                        images: item.items.map(({ image, image_topic }) => { return { image: image, alt: image.alt, topic: image_topic } }),
                    }
                case "icon_and_text":
                    return {
                        slice_type: item.slice_type,
                        mainTitle: item.primary.main_title,
                        name: item.primary.name,
                        icons: item.items.map((icon) => { return { icon: icons[icon.icon_name], title: icon.title, text: icon.icon_text, link: icon.link.url } })
                    }
                case "image":
                    return {
                        slice_type: item.slice_type,
                        image: item.primary.image,
                        alt: item.primary.image.alt,
                    }
                case "divider_icon_text":
                    return {
                        slice_type: item.slice_type,
                        name: item.primary.name,
                        image: item.primary.image,
                        icons: ArrToNestedArr(item.items.map((icon) => { return { icon: icons[icon.name], title: icon.title, text: icon.text, mainTitle: icon.category, link: icon.link?.url } }), "mainTitle")
                    }
            }
        }))
}