import React, { useRef } from "react";
import { motion } from "framer-motion"
import Layout from "../components/layout/Layout";
import Head from "../components/head/Head";
import { graphql } from "gatsby";
import ImageGallery from "../components/imageGallery/ImageGallery";
import { PrismicSliceManipulation } from "../utils/PrismicSliceManipulation";
import Hero from "../components/hero/Hero";
import ImageBackground from "../components/imageBackground/ImageBackground";
import IconPlusText from "../components/iconPlusText/IconPlusText";
import DividerContainer from "../components/dividerContainer/DividerContainer";
import ElementActionWrapper from "../components/elementActionWrapper/ElementActionWrapper";
import CustomLink from "../components/customLink/CustomLink";
import NavbarLogo from "../components/navbarLogo/NavbarLogo";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"
import { useScrolled } from "../hooks/useScrolled";

const IndexPage = ({ data }) => {

  let heroRef = useRef()

  const home = data.prismicPage.data;
  const { scrolled } = useScrolled(heroRef)

  const pageData = {
    title: home.page_title,
    metaTitle: home.meta_title,
    metaDescription: home.meta_description
  }
  
  const homeModified = PrismicSliceManipulation(home.body);

  const hero = homeModified[0];
  const welcome = homeModified[1];
  const imageSushi = homeModified[2];
  const imageFood = homeModified[3];
  const imageDrinks = homeModified[4];
  const imageAmbiente = homeModified[5];
  const cards = homeModified[6]

  const fadeAnimation = {
    initial: { opacity: 0, y: 100 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true },
    transition: { duration: 1 },
  }

  return (
    <div className="page__body">
      <Layout sectionLink={true} showLogo={false} >
        <Head title={pageData.metaTitle} titleSuffix={false} description={pageData.metaDescription} robots="index,follow" />
        <main>
          {scrolled && <CustomLink style={{ position: "fixed", right: "5%", bottom: "5%", zIndex: "10" }} aria-label={`Scrolle nach oben`} link={`/#${hero.slug}`} >
            <ElementActionWrapper animation="up" outline="circle">
              <MdKeyboardArrowUp size={40} />
            </ElementActionWrapper>
          </CustomLink>}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            <div id={`/#${hero.slug}`} style={{ overflowX: "hidden" }} className="page__element no-padding">
              <ImageBackground style={{ height: "100.01vh", width: "100vw" }} img={hero.image} imgAlt={hero.image.alt} opacity="light" animation="zoom" />
            </div>
            <div ref={heroRef} style={{ position: "absolute", top: "0" }} id={hero.slug} className="page__element vp row">
              <NavbarLogo size="big" />
              <h1 className="xl" style={{ textAlign: "left", whiteSpace: "pre-line", lineHeight: "1", margin: "0" }}>{hero.title}</h1>
              <div className="absolute-align__box">
                <CustomLink aria-label="Scrolle nach unten" link={`/#${welcome.slug}`} >
                  <ElementActionWrapper animation="bounce down">
                    <MdKeyboardArrowDown size={60} />
                  </ElementActionWrapper>
                </CustomLink>
              </div>
            </div>
          </motion.div>
          <ImageBackground id={welcome.slug} img={welcome.image} imgAlt={welcome.image.alt} >
            <motion.div {...fadeAnimation}>
              <div className="page__element vp">
                <Hero title={welcome.title} html={welcome.content} buttons={welcome.buttons} />
              </div>
            </motion.div>
          </ImageBackground>
          <motion.div className="page__element small-padding" {...fadeAnimation}>
            <h2 className="underline">{imageSushi.title}</h2>
            <ImageGallery images={imageSushi.images} animation="zoom" />
            <div className="underline m" />
          </motion.div>
          <motion.div className="page__element small-padding" {...fadeAnimation}>
            <h2 className="underline">{imageFood.title}</h2>
            <ImageGallery images={imageFood.images} animation="zoom" />
            <div className="underline m" />
          </motion.div>
          <motion.div className="page__element small-padding" {...fadeAnimation}>
            <h2 className="underline">{imageDrinks.title}</h2>
            <ImageGallery images={imageDrinks.images} animation="zoom" />
            <div className="underline m" />
          </motion.div>
          <motion.div className="page__element small-padding" {...fadeAnimation}>
            <h2 className="underline">{imageAmbiente.title}</h2>
            <ImageGallery images={imageAmbiente.images} animation="zoom" />
            <div className="underline m" />
          </motion.div>
          <ImageBackground id="other" img={cards.image} imgAlt={cards.image.alt} >
            <motion.div {...fadeAnimation}>
              <div className="page__element vp">
                <DividerContainer items={cards.icons} align="vertical">
                  {(props) =>
                    <>
                      <h3 style={{ textAlign: "center" }}>{props[0].mainTitle}</h3>
                      {props.map(({ icon, ...otherProps }, secondIndex) => {
                        let Icon = icon;
                        return (<IconPlusText key={secondIndex} openNewTab={true} {...otherProps}><Icon size={30} /></IconPlusText>)
                      }
                      )}
                    </>
                  }
                  <div className="divider-line" />
                </DividerContainer>
              </div>
            </motion.div>
          </ImageBackground>
        </main>
      </Layout>
    </div >
  )
}

export default IndexPage;

export const query = graphql`
query HomePage {
  prismicPage(uid: {eq: "home"}) {
    uid
    data {
      page_title
      meta_title
      meta_description
      body {
        ... on PrismicPageDataBodyCallToAction {
          slice_type
          items {
            button_color
            button_label
            button_link {
              url
            }
          }
          primary {
            action_title
            image {
              gatsbyImageData
              alt
            }
            content {
              html
            }
            label
            slug
          }
        }
        ... on PrismicPageDataBodyImageGalleryItem {
          slice_type
          primary {
            title
            }
          items {
            image {
              gatsbyImageData
              alt
            }
            image_topic
          }
        }
        ... on PrismicPageDataBodyIconAndText {
          items {
            icon_name
            icon_text
            link {
              url
            }
            title
          }
          slice_type
          primary {
            main_title
            name
          }
        }
        ... on PrismicPageDataBodyImage {
          primary {
            image {
              alt
              gatsbyImageData
            }
          }
          slice_type
        }
        ... on PrismicPageDataBodyDividerIconText {
          id
          slice_type
          primary {
            name
            image {
              gatsbyImageData
            }
          }
          items {
            title
            text
            name
            category
            link {
              url
            }
          }
        }
      }
    }
  }
}
`

