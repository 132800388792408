import { increment, decrement, onSelect, setLength } from "./actionTypes";


export default function sliderReducer(state, action) {
    switch (action.type) {
        case decrement:
            return { ...state, count: state.count === 0 ? state.length - 1 : state.count - 1 };
        case increment:
            return { ...state, count: state.count === state.length - 1 ? 0 : state.count + 1 };
        case onSelect:
            return { ...state, count: action.payload };
        case setLength:
            return { ...state, length: action.payload }
        default:
            throw new Error("Wrong action type")
    }
}

