import React from "react";
import ConditionalComponentWrapper from "../conditionalComponentWrapper/ConditionalComponentWrapper";
import "./iconPlusText.styles.scss";

//TODO Check if refactoring is required
const IconPlusText = ({ title, text, children, onClick, link, openNewTab }) => {
    return (
        <div className={`icon-plus-text${link || onClick ? " link" : ""}`} onClick={onClick} >
            <ConditionalComponentWrapper
                condition={link}
                wrapper={children => <a style={{ width: "100%" }} href={link} {...(openNewTab ? { target: "_blank", rel: "noreferrer" } : {})}>{children}</a>}
            >
                <div className="icon__box">
                    {children}
                </div>
                <div className="text__box">
                    {title && <strong>{title}</strong>}
                    {text && <span style={{ whiteSpace: "pre-line" }}>{text}</span>}
                </div>
            </ConditionalComponentWrapper>
        </div>
    )
}

export default IconPlusText
