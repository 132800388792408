import React, { cloneElement } from "react";
import { increment, decrement } from "../../reducer/actionTypes"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import "./slider.styles.scss";
import useKeyMove from "../../hooks/useKeyMove";
import SliderArrow from "../sliderArrow/SliderArrow";

const Slider = ({ slides, state, dispatch, children }) => {

    //TODO Swipe add on with finger could be added
    useKeyMove(dispatch);

    return (
        <div className="slider">
            <SliderArrow direction="left" onClick={() => dispatch({ type: decrement })} />
            <SliderArrow direction="right" onClick={() => dispatch({ type: increment })} />
            {slides.map((slide, index) => {
                return (
                    <div key={index} className={state.count === index ? "slider__content active" : "slider__content"}>
                        {state.count === index &&
                            cloneElement(children, { ...slide })
                        }
                    </div>
                )
            })}
        </div>
    )
}

export default Slider;
