import React from "react";
import "./elementActionWrapper.styles.scss";

const ElementActionWrapper = ({ style, animation = "", outline = "", position = "", ...otherProps }) => {

    return (
        <div style={style} className={`arrow ${animation} ${outline} ${position}`.trim()} {...otherProps} />
    )
}

export default ElementActionWrapper
