import React, { cloneElement } from "react";
import "./dividerContainer.styles.scss";

const DividerContainer = ({ items, children, align = "" }) => {

    return (
        <div className="divider">
            {items.map((item, index, arr) =>
                <div className="divider__wrapper" key={index}>
                    <div className={`divider__item ${align}`.trim()}>
                        {children[0](item)}
                    </div>
                    {!(index === arr.length - 1) &&
                        <div className="divider__element">
                            {children[1]}
                        </div>}
                </div>
            )}
        </div>
    )
}

export default DividerContainer
