import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./detailImage.styles.scss";

const DetailImage = ({ image, alt, description, link }) => {
    
    return (
        <div className="img-modal__content">
            {image && <>
                <div className={`img-modal__img__container ${(!description && !link) ? "img-only" : "" }`.trim()}>
                    <GatsbyImage image={getImage(image)} alt={alt} objectFit="contain" className="img-modal__img" />
                </div>
                { description && link && <div className="img-modal__info__container">
                    {description && <p>{description}</p>}
                    {link && <a href={link}>{link}</a>}
                </div>}
            </>
            }
        </div>
    )
}

export default DetailImage
