import React from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import "./sliderArrow.styles.scss";

const SliderArrow = ({ direction, onClick }) => {

    const getProps = (direction) => ({
        onClick: () => onClick(),
        className: `slider__arrow ${direction}`
    })

    const renderSwitch = () => {
        switch (direction) {
            case "left":
                return <MdKeyboardArrowLeft {...getProps(direction)} />
            case "right":
                return <MdKeyboardArrowRight {...getProps(direction)} />
        }
    }
    return <>{renderSwitch()}</>;
};

export default SliderArrow;
